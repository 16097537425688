import { IconInfoCircle, IconTruck } from '@tabler/icons-react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { Button } from '~/components/Button'
import Card from '~/components/Card/Card'
import { LoadingSpinner2 } from '~/components/LoadingSpinner2'
import Status from '~/components/Status/Status'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { formatGoogleAddress } from '~/helpers/formatCustomerAddress'
import { useShiipDeliver } from '~/hooks/useShiipDeliver'

const ProductDeliverToPostcodeStatusTooptip = dynamic(
  async () => {
    const mod = await import('./ProductDeliverToPostcodeStatusTooptip')
    return mod.ProductDeliverToPostcodeStatusTooptip
  },
  {
    loading: () => {
      return (
        <span className='z-[11] relative inline-block cursor-pointer'>
          <IconInfoCircle
            size={20}
            color='var(--product-status-tooltip-color)'
            className='inline'
          />
        </span>
      )
    },
    ssr: false,
  }
)

export const ProductDeliverToPostcodeStatusCard = () => {
  const {
    isLoading,
    shiipAddress,
    deliveryInfo,
    promptAddressConfigDrawer,
    findShiipDeliveryOptions,
  } = useShiipDeliver()

  const showFindButton = !isLoading && shiipAddress && !deliveryInfo

  return (
    <Card
      title={
        <>
          <Typography
            tag={TypographyTag.span}
            variant={TypographyVariant.BodyRegularBold}
            className='mr-2'>
            Deliver to{' '}
            {shiipAddress && (
              <span className='underline'>
                {formatGoogleAddress({ ...shiipAddress }, false, true)}
              </span>
            )}
          </Typography>
          <ProductDeliverToPostcodeStatusTooptip
            loading={isLoading}
            deliveryInfo={deliveryInfo}
          />
        </>
      }
      variant='default'
      icon={<IconTruck size={24} />}>
      {isLoading && (
        <div className='flex items-center gap-4'>
          <LoadingSpinner2 />
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyRegular}>
            Please wait while we search far and <br /> wide for couriers
          </Typography>
        </div>
      )}
      {showFindButton && (
        <Button
          size='sm'
          onClick={() => {
            return findShiipDeliveryOptions()
          }}>
          Find delivery options
        </Button>
      )}

      <div
        className={classNames(
          'pl-9',
          isLoading || showFindButton ? 'hidden' : ''
        )}>
        {deliveryInfo ? (
          <>
            {deliveryInfo.CollectionPoints?.map((op, index) => {
              return (
                <div key={index} className='flex items-center'>
                  <Status
                    label={`${op.DeliveryMethod || ''} ${
                      op.DeliveryLabel || ''
                    }`.trim()}
                    status='success'
                    className='py-0.5'
                  />
                  <Typography
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodyRegular}
                    className='ml-4'>
                    {op.DeliveryCharge ? `$${op.DeliveryCharge}` : 'free'}
                  </Typography>
                </div>
              )
            })}
            <Status
              label={deliveryInfo.message || 'No delivery options found'}
              status='error'
              className={classNames(
                'py-0.5',
                deliveryInfo.CollectionPoints?.length > 0 ? 'hidden' : ''
              )}
            />
          </>
        ) : (
          <Typography
            tag={TypographyTag.div}
            variant={TypographyVariant.BodyRegular}>
            Enter address to find delivery availability
          </Typography>
        )}
      </div>

      <button
        aria-label='Add postcode for delivery availability'
        type='button'
        className={classNames(
          'absolute inset-0 bg-transparent border-0 outline-0 z-10 w-full',
          showFindButton && '!bottom-auto h-10'
        )}
        onClick={() => {
          return promptAddressConfigDrawer()
        }}>
        {' '}
      </button>
    </Card>
  )
}
