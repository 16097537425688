import { useMemo } from 'react'
import classNames from 'classnames'
import Status from '~/components/Status/Status'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'

export type HelperTextProps = {
  brand?: string
  availableForDelivery?: string
  inStockText?: string
  inStore?: boolean
  inStock?: boolean
}

export const HelperText = ({
  brand,
  availableForDelivery,
  inStockText,
  inStore,
  inStock,
}: HelperTextProps) => {
  const deliveryStatus = useMemo(() => {
    if (!inStock) {
      return 'error'
    }

    if (
      availableForDelivery &&
      availableForDelivery.toLowerCase() === 'available for delivery'
    ) {
      return 'success'
    }

    return 'warning'
  }, [inStock, availableForDelivery])

  return (
    <div className='productCard-helperText h-12'>
      {availableForDelivery && (
        <Status
          status={deliveryStatus}
          label={
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodySmallExtraLineHeight}
              className='py-0.5 text-primary !pb-0'>
              {availableForDelivery}
            </Typography>
          }
        />
      )}
      {inStockText && (
        <Status
          status={inStore ? 'success' : 'error'}
          label={
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodySmallExtraLineHeight}
              className={classNames(
                brand === 'rtm' && 'whitespace-nowrap',
                'py-0.5 text-primary !pb-0'
              )}>
              {inStockText}
            </Typography>
          }
        />
      )}
    </div>
  )
}
