import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { Button, Link } from '~/components'
import { Image } from '~/components/Image'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import styles from './HeroBanner.module.css'
import { FeatureHeroBannerProps } from './HeroBanner.types'
import HeroBannerBackgroundImage from './HeroBannerBackgroundImage'

export const FeatureHeroBanner = ({
  background,
  action,
  textBackground,
  align,
  textBackgroundColor,
  isCurvedImage,
  skus,
  bannerLogo,
  children,
}: FeatureHeroBannerProps) => {
  const isCenterAligned = align === 'center'

  return (
    <div className={styles.wrapper}>
      <HeroBannerBackgroundImage
        {...background}
        isCurvedImage={isCurvedImage}
      />
      <div
        className={classNames(
          'absolute top-9 md:top-1/2 md:-translate-y-1/2 w-full md:max-w-[375px] xl:max-w-[577px] h-auto px-4 md:px-0',
          isCenterAligned
            ? 'md:left-1/2 md:-translate-x-1/2'
            : 'md:left-10 lg:left-20',
          textBackground ? '3xl:max-w-[577px]' : '3xl:max-w-[690px]'
        )}>
        <div
          className={classNames(
            'flex flex-col gap-6 lg:gap-10 rounded-md',
            textBackground && 'bg-primary-blue px-4 py-6 lg:px-6 lg:py-8',
            isCenterAligned ? 'items-center text-center' : 'items-baseline'
          )}
          style={{
            backgroundColor: textBackgroundColor,
            boxShadow: textBackground
              ? '0px 16px 20px -8px rgba(12, 37, 76, 0.10)'
              : 'none',
          }}>
          {bannerLogo?.logoImage?.src &&
            bannerLogo?.logoPlacement === 'top-heading' && (
              <div className='relative'>
                <Image
                  src={bannerLogo.logoImage.src}
                  alt={bannerLogo.logoImage.altText}
                  width={bannerLogo.logoImage.width}
                  height={bannerLogo.logoImage.height}
                  priority
                  style={{
                    objectFit: 'contain',
                    objectPosition: isCenterAligned ? 'center' : 'left',
                  }}
                />
              </div>
            )}
          {children}
          {!action?.isDisabled && (
            <Button
              status='primary'
              size='lg'
              theme={{
                root: classNames('z-20', styles.button),
              }}
              href={action?.href}
              style={{
                backgroundColor: action?.bgColor,
              }}>
              <Typography
                tag={TypographyTag.p}
                variant={TypographyVariant.BodyRegularBold}>
                <span
                  className={classNames('lg:text-lg lg:leading-6')}
                  style={{
                    color: action?.textColor,
                  }}>
                  {action?.title}
                </span>
              </Typography>
              <VisuallyHidden>{action?.accessibleTitle}</VisuallyHidden>
            </Button>
          )}
        </div>
      </div>
      {!!skus?.length && (
        <div
          className={classNames(
            'flex absolute gap-2 z-20',
            styles.skuContainer
          )}>
          {skus.map((productSku) => {
            return (
              <div key={productSku?.sku}>
                <Link
                  href={productSku.url?.href}
                  title={productSku.url?.title || productSku.title}>
                  <Typography
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodySmall}
                    className={classNames('text-white', styles.sku)}>
                    {productSku?.sku}
                  </Typography>
                  <VisuallyHidden>{productSku?.title}</VisuallyHidden>
                </Link>
              </div>
            )
          })}
        </div>
      )}

      {((bannerLogo?.logoImage?.src && bannerLogo?.logoPlacement === 'right') ||
        bannerLogo?.logoText) && (
          <div
            className={classNames(
              'absolute flex flex-col items-center gap-4',
              styles.logoContainer
            )}>
            {bannerLogo?.logoImage?.src && (
              <div className={styles.logoImage}>
                <Image
                  src={bannerLogo.logoImage?.src}
                  alt={bannerLogo.logoImage?.altText}
                  fill
                  priority
                />
              </div>
            )}
            {bannerLogo?.logoText && (
              <Typography
                tag={TypographyTag.span}
                variant={TypographyVariant.BodySmall}
                className={classNames('uppercase text-white', styles.logoText)}>
                {bannerLogo.logoText}
              </Typography>
            )}
          </div>
        )}
    </div>
  )
}
