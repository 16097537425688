import { Address, CustomerAddress } from 'shared-types'
import { Address as SkdAddress } from 'shared-types/src/commercetools'

export function formatCustomerAddressInArray(address: Address | SkdAddress) {
  if (!address) {
    return []
  }

  const {
    streetNumber,
    streetName,
    city,
    state,
    postalCode,
    country,
    addressLine2,
  } = address

  const splitedAddress: Array<string> = []
  let str = ''

  str = `${streetNumber || ''} ${streetName || ''}`.trim()
  if (str) {
    splitedAddress.push(`${str},`)
  }

  if (addressLine2) {
    splitedAddress.push(addressLine2)
  }

  str = city ? `${city}, ` : ''
  str += `${state} ${postalCode}`.trim()

  if (str) {
    splitedAddress.push(str)
  }

  if (country) {
    splitedAddress.push(country)
  }

  return splitedAddress
}

export function formatCustomerAddress(address: Address) {
  return formatCustomerAddressInArray(address).join(' ')
}

export function compareCustomerAddress(
  address1: CustomerAddress,
  address2: CustomerAddress
) {
  if (!address1 || !address2) {
    return false
  }

  const formattedAddress1 = formatCustomerAddress({
    ...address1,
    streetName: address1.streetName || address1.addressLine1,
  }).trim()

  const formattedAddress2 = formatCustomerAddress({
    ...address2,
    streetName: address2.streetName || address2.addressLine1,
  }).trim()

  return formattedAddress1 === formattedAddress2
}

export function formatGoogleAddress(
  address?: CustomerAddress,
  omitComma = false,
  skipCountry = false
) {
  if (!address) {
    return ''
  }

  const {
    addressLine1,
    addressLine2,
    suburb,
    streetNumber,
    streetName,
    city,
    state,
    postalCode,
    country,
    subpremise,
  } = address

  const subPremise = subpremise ? `${subpremise}/` : ''
  let list = [
    addressLine1 ||
      `${subPremise}${streetNumber || ''} ${streetName || ''}`.trim(),
    addressLine2,
    `${suburb || city || ''} ${state || ''} ${postalCode || ''}`.trim(),
    skipCountry ? '' : country,
  ]

  list = list.filter((li) => {
    return !!li
  })

  if (omitComma) {
    return list.join(' ')
  }

  return list.join(', ')
}

export function formatAddressLine1(
  stNumber: string,
  stName: string,
  subPremise?: string
) {
  const addressLine1 = `${stNumber || ''} ${stName || ''}`.trim()

  if (!addressLine1 || !subPremise) {
    return addressLine1 || ''
  }

  return `${subPremise.trim()}/${addressLine1}`
}
